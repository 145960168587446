import React from "react"

// Types
import { IIconProps } from "./Icon"

const Check = ({ width, height, color, className }: IIconProps) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 21 16"
  >
    <path
      d="M0 7.273l2.8-2.91 5.6 5.819L18.2 0 21 2.91 8.4 16z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
)

export default Check

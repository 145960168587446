import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// Styling
import colors from "../styles/colors"

// Components
import Button from "./Button"
import Icon from "./icons/Icon"
import { navigate } from "@reach/router"

interface IProps {
  label: string
  from: string
  to?: string
  backLabel?: string
}

const PageNavigation = ({ label, from, to, backLabel }: IProps) => (
  <Container>
    <StyledLink to={from}>
      <StyledIcon icon="chevronLeft" color={colors.grey} />
      {backLabel || "Go Back"}
    </StyledLink>
    <Button
      label={label}
      type={to ? "button" : "submit"}
      onClick={() => to && navigate(to)}
    />
  </Container>
)

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.grey};
  font-weight: 700;
`

const StyledIcon = styled(Icon)`
  margin-right: 12px;
`

export default PageNavigation

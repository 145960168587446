import React from "react"

// Types
import { IIconProps } from "./Icon"

const ChevronLeft = ({ width, height, color, className }: IIconProps) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 9 15"
  >
    <path d="M7 15L0 7.5 7 0l2 2-5 5.5L9 13z" fill={color} fillRule="nonzero" />
  </svg>
)

export default ChevronLeft

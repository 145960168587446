import React from "react"

// Types
import { IIconProps } from "./Icon"

const ChevronDown = ({ width, height, color, className }: IIconProps) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 18 11"
  >
    <path
      d="M18 2.444L9 11 0 2.444 2.4 0 9 6.111 15.6 0z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
)

export default ChevronDown

import firebase from "firebase/app"
import "firebase/firestore"

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV

const apiKey =
  activeEnv === "development"
    ? process.env.FIREBASE_API_KEY
    : process.env.GATSBY_FIREBASE_API_KEY

const authDomain =
  activeEnv === "development"
    ? process.env.FIREBASE_AUTH_DOMAIN
    : process.env.GATSBY_FIREBASE_AUTH_DOMAIN

const databaseURL =
  activeEnv === "development"
    ? process.env.FIREBASE_DATABASE_URL
    : process.env.GATSBY_FIREBASE_DATABASE_URL
const storageBucket =
  activeEnv === "development"
    ? process.env.FIREBASE_STORAGE_BUCKET
    : process.env.GATSBY_FIREBASE_STORAGE_BUCKET
const messagingSenderId =
  activeEnv === "development"
    ? process.env.FIREBASE_MESSAGING_SENDER_ID
    : process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID
const projectId =
  activeEnv === "development"
    ? process.env.FIREBASE_PROJECT_ID
    : process.env.GATSBY_FIREBASE_PROJECT_ID
const appId =
  activeEnv === "development"
    ? process.env.FIREBASE_APP_ID
    : process.env.GATSBY_FIREBASE_APP_ID

const config = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
}

firebase.initializeApp(config)

const db = firebase.firestore()

export { db }
export default firebase

import React from "react"
import styled from "styled-components"

// Styling
import colors from "../../../styles/colors"

interface IProps {
  error: any
  className?: string
}

const FieldError = ({ error, className }: IProps) => (
  <Span className={className}>{error}</Span>
)

const Span = styled.span`
  color: ${colors.red};
`

export default FieldError

import ChevronDown from "./ChevronDown"
import ChevronLeft from "./ChevronLeft"
import Check from "./Check"
import Code from "./Code"
import Components from "./Components"
import Guidelines from "./Guidelines"
import InteractionPatterns from "./InteractionPatterns"
import PageTemplates from "./PageTemplates"

const iconMap = {
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  check: Check,
  code: Code,
  frontendCode: Code,
  components: Components,
  guidelines: Guidelines,
  interactionPatterns: InteractionPatterns,
  pageTemplates: PageTemplates,
}

export default iconMap

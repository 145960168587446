import React from "react"
import styled from "styled-components"

// Styling
import textStyles from "../styles/textStyles"
import mq from "../styles/breakpoints"

interface IProps {
  title?: string
  subtitle?: string
  text?: string[]
  marginTop?: boolean
}

const Intro = ({ title, subtitle, text, marginTop }: IProps) => (
  <Container marginTop={marginTop}>
    {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
    {text && text.map((paragraph, i) => <Text key={i}>{paragraph}</Text>)}
  </Container>
)

interface IContainer {
  marginTop: boolean
}
const Container = styled.div<IContainer>`
  ${mq.to.breakpoint.S`
    ${({ marginTop }) => marginTop && `margin-top: 48px;`};
  `};
  margin-bottom: 40px;

  ${mq.from.breakpoint.M`
    margin-bottom: 56px;
  `};
`

const Title = styled.h1`
  ${textStyles.title};
`

const Subtitle = styled.h2`
  ${textStyles.subtitle};
`

const Text = styled.p`
  :last-child {
    margin-bottom: 0;
  }
`

export default Intro

/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import styled from "styled-components"

// Styling
import mq from "../styles/breakpoints"

// Components
import Footer from "./Footer"

interface IProps {
  children: React.ReactNode
}

const Layout = ({ children }: IProps) => (
  <Container>
    <ContentWrapper>{children}</ContentWrapper>
    <Footer />
  </Container>
)

const Container = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
`

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
  padding: 24px;

  ${mq.from.breakpoint.S`
    padding: 40px 24px 24px;
  `};
`

export default Layout

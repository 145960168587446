import React from "react"

// Types
import { IIconProps } from "./Icon"

const PageTemplates = ({ width, height, className }: IIconProps) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 75 58"
  >
    <g fillRule="nonzero" fill="none">
      <path fill="#FFCF8B" d="M23.419 0H12v9.412h53.55V50H75V0z" />
      <path
        d="M0 58h61V14H0v44zm6.243-33h48.384v6.286H6.243V25zm0 12.571h48.384v6.286H6.243v-6.286z"
        fill="#F79D1D"
      />
    </g>
  </svg>
)

export default PageTemplates

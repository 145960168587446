import React from "react"

// Types
import { IIconProps } from "./Icon"

const Components = ({ width, height, className }: IIconProps) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 80 80"
  >
    <g fillRule="nonzero" fill="none">
      <path
        d="M79.82 56.08c0 1.76-1.44 3.2-3.2 3.2h-5.43v11.91h-9.98v-3.5c0-2.83-2.31-5.131-5.13-5.131a5.135 5.135 0 00-5.13 5.131v3.5h-9.97v-9.98h3.51c2.819 0 5.13-2.31 5.13-5.13 0-2.83-2.311-5.13-5.13-5.13h-3.51v-9.99h11.89v-5.42c0-1.77 1.439-3.21 3.21-3.21.88 0 1.68.36 2.26.94.58.58.94 1.38.94 2.27v5.42h11.91v11.91h5.43c1.76 0 3.2 1.44 3.2 3.21z"
        fill="#C42C76"
      />
      <path
        d="M47.67 56.08c0 1.76-1.43 3.2-3.2 3.2h-5.43v11.91H27.15v5.43c0 1.77-1.44 3.2-3.2 3.2-1.77 0-3.21-1.44-3.21-3.2v-5.43H8.83V59.28H3.4a3.21 3.21 0 01-3.2-3.2 3.21 3.21 0 013.2-3.21h5.43V40.96h11.91v-5.42c0-1.77 1.43-3.21 3.2-3.21 1.76 0 3.19 1.44 3.19 3.21v5.42h11.91v11.91h5.43c1.77 0 3.2 1.44 3.2 3.21z"
        fill="#C42C76"
      />
      <path
        d="M30.41 23.92c0 2.83 2.3 5.13 5.13 5.13h3.5v9.99h-9.96v-3.5a5.14 5.14 0 00-5.13-5.13h-.02c-2.83 0-5.13 2.3-5.13 5.13v3.5H8.81V27.13H3.38a3.21 3.21 0 01-3.2-3.2c0-1.77 1.44-3.21 3.2-3.21h5.43V8.81h11.91V3.38c0-1.76 1.44-3.2 3.21-3.2 1.76 0 3.2 1.44 3.2 3.2v5.43h11.91v9.99h-3.5c-2.83 0-5.13 2.3-5.13 5.12z"
        fill="#C42C76"
      />
      <path
        d="M62.55 23.92c0 2.83 2.311 5.13 5.13 5.13h3.511v9.99h-9.98v-3.5c0-1.42-.58-2.7-1.51-3.63a5.104 5.104 0 00-3.62-1.5c-2.83 0-5.13 2.3-5.13 5.13v3.5h-9.99V27.13h-5.43c-1.76 0-3.2-1.44-3.2-3.21 0-1.76 1.44-3.2 3.2-3.2h5.43V8.81h11.91V3.38a3.21 3.21 0 013.21-3.2c1.76 0 3.2 1.44 3.2 3.2v5.43h11.91v9.99H67.68c-2.82 0-5.13 2.3-5.13 5.12z"
        fill="#E65EA0"
      />
    </g>
  </svg>
)

export default Components

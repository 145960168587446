import React from "react"
import styled from "styled-components"

// Images
import ICTULogo from "../images/ictu-logo.png"
import GCLogo from "../images/gebruikers-centraal-logo.png"
import JMLogo from "../images/jungle-minds-logo.png"

// Styling
import colors from "../styles/colors"
import mq from "../styles/breakpoints"

// Components
import Logo from "./Logo"

interface IProps {
  className?: string
  extended?: boolean
}

const Footer = ({ className, extended }: IProps) => (
  <Container className={className}>
    <ContentWrapper extended={extended}>
      <Text>Powered by:</Text>
      <LogoContainer>
        <StyledLogo
          src={GCLogo}
          alt="Gebruikers Centraal logo"
          url="https://www.gebruikercentraal.nl/"
        />
        <StyledLogo
          src={JMLogo}
          alt="Jungle Minds logo"
          url="https://www.jungleminds.nl/"
        />
      </LogoContainer>
    </ContentWrapper>
  </Container>
)

const Container = styled.footer`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  background-color: ${colors.white};
`

interface IContentWrapper {
  extended?: boolean
}

const ContentWrapper = styled.div<IContentWrapper>`
  width: 100%;
  max-width: ${({ extended }) => (extended ? "1200" : "740")}px;
  margin: 0 auto;
  padding: 24px 24px 48px;

  ${mq.from.breakpoint.M`
    padding: 40px 24px 72px;
  `};
`

const Text = styled.p`
  margin-bottom: 12px;
  color: ${colors.offGrey};
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 800;

  ${mq.from.breakpoint.M`
    margin-bottom: 16px;
  `};
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledLogo = styled(Logo)`
  margin-right: 24px;

  :last-child {
    margin-right: 0;
  }
`

export default Footer

import React from "react"

// Types
import { IIconProps } from "./Icon"

const InteractionPatterns = ({ width, height, className }: IIconProps) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 62 84"
  >
    <g fill="none" fillRule="evenodd">
      <circle fill="#8EBDDF" cx={26.5} cy={17.5} r={17.5} />
      <path
        d="M61.711 49.952c-1.121-6.702-5.862-7.747-8.055-7.862a2.12 2.12 0 01-1.682-.955c-1.606-2.46-3.76-3.11-5.595-3.11-.816 0-1.568.128-2.18.28-.19.052-.37.077-.56.077a2.133 2.133 0 01-1.747-.879c-1.58-2.166-3.632-2.74-5.404-2.74-.433 0-.854.026-1.236.09-.115.012-.714.025-.816.025-1.185 0-2.663-.956-2.663-2.179V19.92c0-3.288-2.524-4.919-4.984-4.919s-4.97 1.631-4.97 4.919v31.448c0 2.459-1.479 4.243-3.697 4.243-.65 0-1.058-.166-1.695-.497-3.008-1.593-6.105-2.982-9.457-4.294-.752-.293-1.415-.433-2.09-.433-4.614 0-7.176 6.078-2.078 9.353 5.85 3.771 13.294 5.823 16.174 10.079 1.785 2.637 5.544 10.092 6.997 13.035.37.752 1.135 1.147 1.963 1.147H50.93c1.083 0 2-.74 2.154-1.822l1.21-9.085c.039-.332.154-.625.332-.892 6.347-9.66 8.157-15.903 7.086-22.249z"
        fill="#3495DA"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default InteractionPatterns

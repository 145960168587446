import React from "react"

// Types
import { IIconProps } from "./Icon"

const Guidelines = ({ width, height, className }: IIconProps) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 58 69"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#9A69D2" d="M6 6h52v63H6z" />
      <path fill="#FFF" d="M5 5h49v60H5z" />
      <path fill="#5F4083" d="M0 0h52v63H0z" />
      <path
        fill="#FFF"
        d="M3 6h43v2H3zM3 12h36v2H3zM3 18h43v2H3zM3 24h28v2H3zM3 30h43v2H3zM3 36h34v2H3zM3 42h25v2H3zM3 48h40v2H3zM3 54h43v2H3z"
      />
    </g>
  </svg>
)

export default Guidelines

import React from "react"
import styled, { css } from "styled-components"
import { navigate } from "gatsby"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import { fastTransition } from "../styles/animations"
import mq from "../styles/breakpoints"

interface IProps {
  currentStep: 0 | 1 | 2 | 3 | 4
}

const ProgressTracker = ({ currentStep }: IProps) => (
  <>
    <LineContainer>
      <LineOne active={currentStep > 1} />
      <LineTwo active={currentStep > 2} />
    </LineContainer>
    <StepContainer>
      <Step
        onClick={() => navigate("/details")}
        disabled={currentStep < 2}
        current={currentStep === 1}
        active={currentStep > 1}
      >
        <Text>
          The
          <br />
          basics
        </Text>
      </Step>
      <Step
        onClick={() => navigate("/themes")}
        disabled={currentStep < 3}
        current={currentStep === 2}
        active={currentStep > 2}
      >
        <Text>
          Pick a<br />
          topic
        </Text>
      </Step>
      <Step
        disabled={currentStep < 4}
        current={currentStep === 3}
        active={currentStep > 3}
      >
        <Text>Select your sub-topic(s)</Text>
      </Step>
    </StepContainer>
  </>
)

const LineContainer = styled.div`
  position: relative;
`

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;

  ${mq.from.breakpoint.M`
    margin-top: 51px;
    margin-bottom: 80px;
  `};
`

const lineStyling = css`
  position: absolute;
  top: 12px;
  width: calc(50% - 60px);
  height: 4px;
  background-color: ${colors.lightGrey};
  transform: translateY(-50%);
  ${({ active }: { active: boolean }) =>
    active &&
    `
    background-color: ${colors.red};
  `};

  ${mq.from.breakpoint.M`
    top: calc(50% + 63px);
  `};
`

const LineOne = styled.div`
  ${lineStyling};
  left: 36px;
`

const LineTwo = styled.div`
  ${lineStyling};
  right: 36px;
`

const Text = styled.span`
  ${textStyles.bodySmall};
  position: absolute;
  top: -55px;
  left: 50%;
  max-width: 120px;
  min-width: 90px;
  margin-bottom: 0;
  color: ${colors.grey};
  transform: translateX(-50%);
  transition: color ${fastTransition};
  ${mq.to.breakpoint.M`display: none;`};
`

interface IStep {
  current: boolean
  active: boolean
}
const Step = styled.button<IStep>`
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
  width: 0;
  height: 0;
  padding: 8px;
  background-color: transparent;
  border-radius: 50%;
  border: 4px solid ${colors.lightGrey};

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
  `};

  ${({ current }) =>
    current &&
    `
    border: 4px solid ${colors.red};
  `};

  ${({ active }) =>
    active &&
    `
    border: 4px solid ${colors.red};
    background-color: ${colors.red};

    :hover {
      ${Text} {
        color: ${colors.red};
      }
    }
  `};
`

export default ProgressTracker

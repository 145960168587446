import React from "react"
import styled from "styled-components"

interface IProps {
  src: string
  alt: string
  url: string
  className?: string
}

const Logo = ({ src, alt, url, className }: IProps) => (
  <Link
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className={className}
  >
    <Image src={src} alt={alt} />
  </Link>
)

const Image = styled.img`
  max-height: 20px;
  margin-bottom: 0;
`

const Link = styled.a`
  display: flex;
`

export default Logo

import React from "react"

// Types
import { IIconProps } from "./Icon"

const Code = ({ width, height, className }: IIconProps) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 70 59"
  >
    <g fillRule="nonzero" fill="none">
      <path
        d="M0 59h70V20H0v39zm41.122-28.687l3.223-2.397L53.991 39.5l-9.646 11.583-3.223-2.396 7.65-9.187-7.65-9.187zm-8.173-2.448h4.102v23.27h-4.102v-23.27zm-7.294.051l3.223 2.397-7.65 9.187 7.65 9.187-3.223 2.396L16.009 39.5l9.646-11.584z"
        fill="#3BB4AC"
      />
      <path
        d="M0 0v16h70V0H0zm12.313 9.998H8.208V6.001h4.105v3.997zm8.21 0h-4.105V6.001h4.105v3.997zm8.21 0h-4.105V6.001h4.104v3.997z"
        fill="#86D3CD"
      />
    </g>
  </svg>
)

export default Code

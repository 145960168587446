import React from "react"
import styled from "styled-components"

// Styles
import colors from "../styles/colors"

interface IProps {
  label: string | React.ReactNode
  type?: "button" | "submit"
  onClick?: () => void
  className?: string
}

const Button = ({ label, type = "button", onClick, className }: IProps) => (
  <Btn type={type} onClick={onClick} className={className}>
    {label}
  </Btn>
)

const Btn = styled.button`
  cursor: pointer;
  padding: 12px 32px;
  background-color: ${colors.green};
  color: ${colors.white};
  border: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0 2px 4px 0 rgba(169, 169, 169, 0.25);
`

export default Button

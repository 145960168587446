import colors from "../styles/colors"

export const themeData = {
  "1-interactionPatterns": {
    name: "Interaction Patterns",
    description: "Common design solutions for user interfaces.",
    color: colors.themeBlue,
    subjects: [
      {
        title: "Data visualization graphs",
        name: "data-visualization-graphs",
      },
      {
        title: "Data visualization tables",
        name: "data-visualization-tables",
      },
      {
        title: "Questionnaire",
        name: "questionnaire",
      },
      {
        title: "Digital identification functionality",
        name: "digital-identification-functionality",
      },
      {
        title: "Contact form",
        name: "contact-form",
      },
      {
        title: "Form validation",
        name: "form-validation",
      },
      {
        title: "Complex (multipage) forms",
        name: "complex-forms",
      },
      {
        title: "Error handling",
        name: "error-handling",
      },
      {
        title: "navigation",
        name: "navigation",
      },
      {
        title: "Digital identification",
        name: "digital-identification",
      },
    ],
  },
  "2-components": {
    name: "Components",
    description:
      "Functional, reusable parts of the user interface that have been made to support a variety of applications. ",
    color: colors.themePink,
    subjects: [
      {
        title: "Datepicker",
        name: "datepicker",
      },
      {
        title: "Accordion",
        name: "accordion",
      },
      {
        title: "Side navigation",
        name: "side-navigation",
      },
      {
        title: "Breadcrumbs",
        name: "breadcrumbs",
      },
      {
        title: "Interactive tables",
        name: "interactive-tables",
      },
      {
        title: "Videos",
        name: "videos",
      },
      {
        title: "Maps",
        name: "maps",
      },
      {
        title: "Form validation",
        name: "form-validation",
      },
      {
        title: "Skip links",
        name: "skip-links",
      },
    ],
  },
  "3-pageTemplates": {
    name: "Page Templates",
    description:
      "With page templates it is possible to build a page or templates for common forms.",
    color: colors.themeOrange,
    subjects: [
      {
        title: "Error pages",
        name: "error-pages",
      },
      {
        title: "Conformation pages",
        name: "conformation-pages",
      },
      {
        title: "Contact form",
        name: "contact-form",
      },
      {
        title: "Digital identification functionality",
        name: "digital-identification-functionality",
      },
      {
        title: "Complex (multipage) forms",
        name: "complex-forms",
      },
    ],
  },
  "4-frontendCode": {
    name: "Front-end Code",
    description:
      "Converting data to a user interface and designing different page types.",
    color: colors.themeCyan,
    subjects: [
      {
        title: "Stylesheets",
        name: "stylesheets",
      },
      {
        title: "Data visualization graphs",
        name: "data-visualization-graphs",
      },
      {
        title: "Interactive tables",
        name: "interactive-tables",
      },
      {
        title: "HTML templates",
        name: "html-templates",
      },
      {
        title: "Specific libraries (JavaScript)",
        name: "specific-libraries",
      },
      {
        title: "Maps",
        name: "maps",
      },
      {
        title: "Form validation",
        name: "form-validation",
      },
      {
        title: "Videos",
        name: "videos",
      },
    ],
  },
  "5-guidelines": {
    name: "Guidelines",
    description:
      "Core principles and conditions that provide guidance for using (parts of) the Design system ",
    color: colors.themePurple,
    subjects: [
      {
        title: "Design principles",
        name: "design-principles",
      },
      {
        title: "Visual identity",
        name: "visual-identity",
      },
      {
        title: "How to’s (manual)",
        name: "manual",
      },
      {
        title: "Accessibility",
        name: "accessibility",
      },
      {
        title: "Code guidelines",
        name: "code-guidelines",
      },
      {
        title: "Performance guidelines",
        name: "performance-guidelines",
      },
      {
        title: "Error handling",
        name: "error-handling",
      },
    ],
  },
}
